import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from "react-icons/fi";
import { MdOutlineVisibility } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";
// import { FaHandshake } from "react-icons/fa";

// import imgDefault from '../../assets/image/placeholder.png'
// import { MdHouseSiding } from "react-icons/md";


function Values_2({ image1 }) {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: (
        <FiTarget fontSize={70} className="bg-2 text-white rounded-full p-4" />
      ),
    },
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: (
        <MdOutlineVisibility
          fontSize={70}
          className="bg-white color-2 rounded-full p-4 border-[2px] border-gray-500"
        />
      ),
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: (
        <FaHandshake
          fontSize={70}
          className="bg-2 text-white rounded-full p-4"
        />
      ),
    },
  ];

  return (
    <>
      <section className="md:flex md:flex-row justify-center items-center m-8 gap-6 ">
        {valuesData.map((items, index) => {
          return (
                <div className="border rounded p-5 justify-center text-center items-center my-8" key={index}>
                    <div className="flex text-center justify-center">{items.icon}</div>
                    <h4>{items.title}</h4>
                    <p>{items.description}</p>
                </div>
          );
        })}
      </section>
    </>
  );
}

export default Values_2;
